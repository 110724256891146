import { startCase } from 'lodash'

export function enumFormatter({ value }) {
	return startCase(value)
}
export function currencyFormatter({ value }) {
	if (!value) return
	return formatCurrency(value)
}

export function currencyWithDashFormatter({ value }) {
	if (!value) return '-'
	return formatCurrency(value)
}

export function dashIfEmptyFormatter({ value }) {
	if (!value) return '-'
	return value
}

export function ssnFormatter({ value }) {
	if (!value) return
	return `XXX-XX-${value.slice(-4)}`
}

// HELPERS

function formatCurrency(number) {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
	}).format(number)
}
