import ReactOnRails from 'react-on-rails'
import '@hotwired/turbo-rails'

import SearchableSelect from '../components/SearchableSelect'
import AgGridTable from '../components/AgGridTable'
import StatusRenderer from '../components/StatusRenderer'
import ImportCurrentYearMemberDataButton from '../components/ImportCurrentYearMemberDataButton'
import ImportFinancialDataButton from '../components/ImportFinancialDataButton'
import ImportPaymentRegisterButton from '../components/ImportPaymentRegisterButton'
import CurrentYearMembersTable from '../components/CurrentYearMembersTable'
import EmptyState from '../components/EmptyState'
import ImportHistoricalMemberDataButton from '../components/ImportHistoricalMemberDataButton'
import HistoricalMembersTable from '../components/HistoricalMembersTable'
import ActionHeader from '../components/ActionHeader'
import '../stylesheets/application.scss'
import '../images/index.js'
import Rails from '@rails/ujs'
import '../controllers'

Rails.start()

ReactOnRails.setOptions({ turbo: true })

ReactOnRails.register({
	AgGridTable,
	ActionHeader,
	CurrentYearMembersTable,
	EmptyState,
	HistoricalMembersTable,
	ImportCurrentYearMemberDataButton,
	ImportFinancialDataButton,
	ImportPaymentRegisterButton,
	ImportHistoricalMemberDataButton,
	SearchableSelect,
	StatusRenderer,
})
