const customValidations = {
	requiredKeys: [
		'previous_market_value',
		'previous_interest_rate',
		'ava_gl_basis',
		'smoothing_period',
	],
}

function financialDataFlatfileValidations(record) {
	customValidations.requiredKeys.forEach((key) => {
		const recordKey = record.get('key')
		if (recordKey !== key) return null

		const value = record.get('value')

		if (!value) {
			record.addError('value', 'Field is required')
		}
	})
}

export default financialDataFlatfileValidations
