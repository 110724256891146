import React, { memo } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import Modal from 'react-modal'

const propTypes = {
	handleConfirm: PropTypes.func,
	modalShown: PropTypes.bool,
	setModalShown: PropTypes.func,
	modalTitle: PropTypes.string,
	itemList: PropTypes.array,
	confirmationText: PropTypes.string,
}

const defaultProps = {
	confirmationText: 'Confirm',
}

function LegendModal({
	modalShown,
	setModalShown,
	modalTitle,
	itemList,
	handleConfirm,
	confirmationText,
}) {
	return (
		<div className="modal">
			<Modal
				isOpen={modalShown}
				ariaHideApp={false}
				contentLabel=""
				onRequestClose={() => setModalShown(false)}
				shouldCloseOnOverlayClick={true}
				className="modal-content"
				overlayClassName="modal-overlay"
			>
				<button onClick={() => setModalShown(false)} className="modal-close">
					X
				</button>
				<h3>{modalTitle}</h3>
				<div>
					<ul className="modal-list">
						{itemList.map((item, i) => (
							<li key={i} className="modal-list-item">
								<img
									className="modal-list-item-image"
									src={item.icon}
									alt="Descriptive text"
								/>
								<span className="modal-list-item-text"> {item.legend} </span>
							</li>
						))}
					</ul>
				</div>
				<div className="modal-buttons">
					<button
						className="button-primary button-small"
						onClick={handleConfirm}
					>
						{confirmationText}
					</button>
				</div>
			</Modal>
		</div>
	)
}

LegendModal.propTypes = exact(propTypes)
LegendModal.defaultProps = defaultProps

export default memo(LegendModal)
