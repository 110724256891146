import { snakeCase } from 'lodash'

function snakeCaseKeys(obj) {
	const transformed = {}

	Object.keys(obj).forEach((key) => {
		const snakeCaseKey = snakeCase(key)
		transformed[snakeCaseKey] = obj[key].member
	})
	return transformed
}

export default snakeCaseKeys
