import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import * as Images from 'images'

const propTypes = {
	url: PropTypes.string.isRequired,
	iconName: PropTypes.string.isRequired,
}

const defaultProps = {}

function LinkRenderer({ url, iconName }) {
	return (
		<a href={url}>
			<img src={get(Images, iconName)} alt={iconName} />
		</a>
	)
}

LinkRenderer.propTypes = propTypes
LinkRenderer.defaultProps = defaultProps

export default memo(LinkRenderer)
