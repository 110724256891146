import React, { memo } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { CloseOutlineWhite, CloseOutline } from 'images'

const propTypes = {
	errorMessage: PropTypes.string.isRequired,
	setErrorMessage: PropTypes.func.isRequired,
}

const defaultProps = {}

function ErrorMessage({ errorMessage, setErrorMessage }) {
	return (
		<div className="toast-message alert">
			<div className="text-block">
				<img
					className="icon"
					src={CloseOutlineWhite}
					alt="Close Error Message"
				/>
				<p>{errorMessage}</p>
			</div>
			<button className="close-toast" onClick={() => setErrorMessage(null)}>
				<img src={CloseOutline} alt="Close Error Message" />
			</button>
		</div>
	)
}

ErrorMessage.propTypes = exact(propTypes)
ErrorMessage.defaultProps = defaultProps

export default memo(ErrorMessage)
