import api from '@flatfile/api'

export async function completeJob(jobId) {
	await api.jobs.complete(jobId, {
		outcome: {
			acknowledge: true,
			message: 'This import is now complete',
			next: {
				type: 'wait',
			},
		},
	})
}

export async function failJob(jobId) {
	await api.jobs.fail(jobId, {
		outcome: {
			message:
				'This import encountered an error. Please contact an administrator.',
		},
	})
}

export async function cancelJob(jobId, errorMessage) {
	await api.jobs.cancel(jobId, { info: errorMessage })
}
