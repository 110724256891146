import { snakeCase } from 'lodash'

function paymentRegisterSerializer(row) {
	const obj = row.values || {}
	const transformed = Object.keys(obj).reduce((acc, key) => {
		const snakeCaseKey = snakeCase(key)

		acc[snakeCaseKey] = obj[key].value
		return acc
	}, {})

	return transformed
}

export default paymentRegisterSerializer
