import React, { memo, useMemo, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'components'

const propTypes = {
	data: PropTypes.object.isRequired,
	api: PropTypes.object.isRequired,
	node: PropTypes.object.isRequired,
}

const defaultProps = {}

function WarningsRenderer({ data, node, api }) {
	const dataStatus = data.dataManagementStatus.member
	const [invalidColIndex, setInvalidColIndex] = useState(0)
	const [tooltipVisible, setTooltipVisible] = useState(false)
	const [tooltipContent, setTooltipContent] = useState('')
	const buttonRef = useRef(null)

	const invalidColKeys = useMemo(
		() =>
			Object.entries(data).reduce((acc, [key, value]) => {
				if (value.invalid || value.needsReconciliation) {
					return [...acc, [`${key}.member`]]
				}
				return acc
			}, []),
		[data],
	)

	function handleWarningsClick() {
		const isLast = invalidColIndex === invalidColKeys.length - 1
		const index = isLast ? 0 : invalidColIndex + 1
		setInvalidColIndex(index)

		const colKey = invalidColKeys[index]
		api.ensureColumnVisible(colKey, 'middle')
		api.flashCells({ rowNodes: [node], columns: [colKey] })
	}

	if (invalidColKeys.length === 0 || dataStatus === 'reviewed') return null

	function showTooltip(content) {
		setTooltipContent(content)
		setTooltipVisible(true)
	}

	function hideTooltip() {
		setTooltipVisible(false)
	}

	return (
		<div className="cell-warnings">
			<div className="btn-warning-circle-wrapper">
				<button
					ref={buttonRef}
					className="btn-warning-circle"
					onClick={handleWarningsClick}
					alt="Go to next warning"
					onMouseEnter={() => showTooltip('Click to jump to next issue')}
					onMouseLeave={hideTooltip}
				/>
			</div>
			<div>{invalidColKeys.length}</div>
			<Tooltip referenceElement={buttonRef.current} visible={tooltipVisible}>
				{tooltipContent}
			</Tooltip>
		</div>
	)
}

WarningsRenderer.propTypes = propTypes
WarningsRenderer.defaultProps = defaultProps

export default memo(WarningsRenderer)
