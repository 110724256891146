import axios from 'axios'
import snakeCaseKeys from '../utils/snakeCaseKeys'

const api = axios.create({
	baseURL: '/',
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
})

export async function createHistoricalMemberData(
	members,
	planFiscalYearDataId,
) {
	const response = await api.post(
		`/api/plan_fiscal_year_data/${planFiscalYearDataId}/historical_member_data`,
		{
			members,
		},
	)
	return response.data
}

export async function updateHistoricalMemberData(
	members,
	planFiscalYearDataId,
) {
	const transformedMembers = members.map(snakeCaseKeys)

	const response = await api.patch(
		`/api/plan_fiscal_year_data/${planFiscalYearDataId}/historical_member_data`,
		{
			members: transformedMembers,
		},
	)
	return response.data
}

export async function createCurrentYearMemberData(
	members,
	planFiscalYearDataId,
) {
	const response = await api.post(
		`/api/plan_fiscal_year_data/${planFiscalYearDataId}/current_year_member_data`,
		{
			members: members,
		},
	)
	return response.data
}

export async function updateCurrentYearMemberData(
	members,
	planFiscalYearDataId,
) {
	const transformedMembers = members.map(snakeCaseKeys)

	const response = await api.patch(
		`/api/plan_fiscal_year_data/${planFiscalYearDataId}/current_year_member_data`,
		{
			members: transformedMembers,
		},
	)
	return response.data
}

export async function createFinancialData(financialData, planFiscalYearDataId) {
	const response = await api.post(
		`/api/plan_fiscal_year_data/${planFiscalYearDataId}/financial_data`,
		{
			financial_data: financialData,
		},
	)
	return response.data
}

export async function createPaymentRegisterData(
	paymentRegisterData,
	planFiscalYearDataId,
	month,
	fileName,
) {
	const response = await api.post(
		`/api/plan_fiscal_year_data/${planFiscalYearDataId}/payment_register_data`,
		{
			payment_register_data: paymentRegisterData,
			plan_fiscal_year_data_id: planFiscalYearDataId,
			month: month,
			file_name: fileName,
		},
	)
	return response.data
}

export async function deleteSpace(spaceId) {
	const response = await api.delete(`/api/spaces/${spaceId}`)
	return response.data
}

export async function deleteAdjustment(adjustmentId, planFiscalYearDataId) {
	const response = await api.delete(
		`/api/ledger_adjustments/${adjustmentId}?plan_fiscal_year_data_id=${planFiscalYearDataId}`,
	)
	return response.data
}
