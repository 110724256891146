import { first, last } from 'lodash'

function splitNameByComma(record) {
	const nameVal = record.get('name')
	const firstNameVal = record.get('firstName')
	const lastNameVal = record.get('lastName')

	if (nameVal && !firstNameVal && !lastNameVal) {
		const splitName = nameVal.split(',').map((str) => str.trim())
		record.set('lastName', first(splitName))
		record.set('firstName', last(splitName))
	}
	return record
}

export default splitNameByComma
