import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = [
		'fileName',
		'fileInput',
		'removeButton',
		'fileUploadContainer',
		'fileNameContainer',
	]

	displayFileName() {
		const inputElement = this.fileInputTarget
		const file = inputElement.files[0]
		if (file) {
			this.fileNameTarget.textContent = 'Uploaded file: ' + file.name
			this.fileNameContainerTarget.style.display = 'flex'
			this.fileUploadContainerTarget.style.display = 'none'
			this.removeButtonTarget.style.display = 'inline'
		} else {
			this.fileNameTarget.textContent = ''
			this.fileNameContainerTarget.style.display = 'none'
			this.fileUploadContainerTarget.style.display = 'flex'
			this.removeButtonTarget.style.display = 'none'
		}
	}

	removeFile() {
		const inputElement = this.fileInputTarget
		inputElement.value = null
		this.displayFileName()
	}
}
