import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import {
	AgGridTable,
	ActionsRenderer,
	ErrorMessage,
	LegendModal,
	WarningsRenderer,
} from 'components'
import { reconciliationList } from 'config'
import { updateHistoricalMemberData } from 'api'

const propTypes = {
	canEdit: PropTypes.bool.isRequired,
	columnDefs: PropTypes.array.isRequired,
	currentUserRole: PropTypes.string.isRequired,
	planFiscalYearDataId: PropTypes.number.isRequired,
	planId: PropTypes.number.isRequired,
	rowData: PropTypes.array.isRequired,
}

const defaultProps = {}

function HistoricalMembersTable({
	canEdit,
	columnDefs,
	currentUserRole,
	planFiscalYearDataId,
	planId,
	rowData,
}) {
	const [members, setMembers] = useState(rowData)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)
	const [modalShown, setModalShown] = useState(false)

	const historicalReconciliationList = reconciliationList.filter(
		(item) => item['historical'] == true,
	)

	// Action Handling

	const actionsColumn = {
		cellRenderer: ActionsRenderer,
		cellRendererParams: {
			onCellValueChanged: onCellValueChanged,
			currentUserRole: currentUserRole,
		},
		field: 'actions',
		headerName: 'Actions',
		pinned: 'right',
		width: 100,
		minWidth: 100,
		maxWidth: 100,
		cellClass: 'shadow-column-right',
	}

	const warningsColumn = {
		cellRenderer: WarningsRenderer,
		cellClass: 'partial-border-right',
		field: 'warnings',
		headerName: '',
		pinned: 'left',
		width: 80,
		resizable: false,
	}

	// Handlers

	function onCellValueChanged(updatedRowData) {
		const data = updatedRowData?.data ? updatedRowData.data : updatedRowData
		setMembers(
			members.map((member) =>
				member.id.member === data.id.member ? data : member,
			),
		)
	}

	function getRowClass(params) {
		const dataStatus = params.data.dataManagementStatus.member
		return dataStatus === 'reviewed' && 'shaded-row'
	}

	async function handleSave() {
		setIsSubmitting(true)
		try {
			await updateHistoricalMemberData(members, planFiscalYearDataId)
			window.location.href = `/plan_fiscal_year_data?plan_id=${planId}`
		} catch (updateError) {
			setErrorMessage(
				'There was an issue updating members. Please contact an administrator.',
			)
		}
		setIsSubmitting(false)
	}

	return (
		<>
			<div className="no-sidebar-content">
				{errorMessage && (
					<ErrorMessage
						errorMessage={errorMessage}
						setErrorMessage={setErrorMessage}
					/>
				)}
				<header className="content-header">
					<div className="header-title">
						<h1>Members</h1>
					</div>
					<div className="details">
						<div className="manage-details">
							<p>
								Review, edit, and organize invalid member data. Identify errors
								with highlighted cells, make edits, and efficiently flag rows as
								reviewed for a cleaner final submission.
							</p>
							<button
								className="button-neutral-outline button-small legend"
								onClick={() => setModalShown(true)}
							>
								View Legend
							</button>
						</div>
					</div>
				</header>
				<AgGridTable
					rowData={members}
					columnDefs={[warningsColumn, ...columnDefs, actionsColumn]}
					canEdit={canEdit}
					fitToGrid={false}
					showExportButton={false}
					onCellValueChanged={onCellValueChanged}
					getRowClass={getRowClass}
				/>
				{modalShown && (
					<LegendModal
						modalShown={modalShown}
						setModalShown={setModalShown}
						modalTitle="Table Legend"
						itemList={historicalReconciliationList}
						handleConfirm={() => setModalShown(false)}
						confirmationText="Done"
					/>
				)}
			</div>
			<div className="actions">
				<button
					className="button-primary button-small"
					onClick={handleSave}
					disabled={isSubmitting}
				>
					{isSubmitting ? 'Saving...' : 'Save & Close'}
				</button>
			</div>
		</>
	)
}

HistoricalMembersTable.propTypes = propTypes
HistoricalMembersTable.defaultProps = defaultProps

export default memo(HistoricalMembersTable)
