import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="collapsible"
export default class extends Controller {
	static targets = ['content', 'icon']

	toggle(event) {
		const contentId = event.currentTarget.getAttribute('data-id')
		const targets = [...this.contentTargets, ...this.iconTargets]
		const elements = targets.filter(
			(element) => element.getAttribute('data-id') === contentId,
		)
		elements.forEach((element) => element.classList.toggle('hidden'))
	}
}
