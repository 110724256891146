import React, { memo } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { EmptyStateCactus } from 'images'

const propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	imageAltText: PropTypes.string.isRequired,
	imageSource: PropTypes.string,
}
const defaultProps = {
	imageSource: EmptyStateCactus,
}

function EmptyState({ title, text, imageSource, imageAltText }) {
	return (
		<div className="empty-state-box">
			<img src={imageSource} alt={imageAltText} />
			<h2>{title}</h2>
			<p>{text}</p>
		</div>
	)
}

EmptyState.propTypes = exact(propTypes)
EmptyState.defaultProps = defaultProps

export default memo(EmptyState)
