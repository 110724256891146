import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isEmpty, camelCase } from 'lodash'
import { enumFormatter } from '../utils/formatters'

const propTypes = {
	value: PropTypes.string,
	colorMap: PropTypes.object,
	statusMap: PropTypes.object,
}

const defaultProps = {}

function StatusRenderer({ value, colorMap, statusMap }) {
	const displayValue = isEmpty(statusMap)
		? enumFormatter({ value })
		: statusMap[camelCase(value)]

	return (
		<>
			{value && colorMap && (
				<span className={classNames('status', colorMap[camelCase(value)])}>
					{displayValue}
				</span>
			)}
		</>
	)
}

StatusRenderer.propTypes = propTypes
StatusRenderer.defaultProps = defaultProps

export default memo(StatusRenderer)
