import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = ['container']

	open() {
		this.containerTarget.style.display = 'flex'
	}

	close() {
		this.containerTarget.style.display = 'none'
	}
}
