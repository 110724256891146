import { first } from 'lodash'

function reconciliationToolTipGetter(params) {
	const { colDef, data } = params
	const fieldKey = first(colDef.field.split('.'))
	const validationError = data[fieldKey]?.validationError
	return validationError || false
}

export default reconciliationToolTipGetter
