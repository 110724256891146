import React, { useCallback } from 'react'
import { useGridFilter } from 'ag-grid-react'

const FILTER_TYPES = {
	IN_PAY: 'in_pay',
	ALL: 'all',
}

function InPayStatusFilter({
	model,
	onModelChange,
	getValue,
	memberInPaymentStatuses,
}) {
	const doesFilterPass = useCallback(
		({ node }) =>
			model === FILTER_TYPES.ALL ||
			memberInPaymentStatuses.includes(getValue(node)),
		[model, memberInPaymentStatuses, getValue],
	)

	const onFilterChange = (e) => {
		onModelChange(e.target.value)
	}

	useGridFilter({ doesFilterPass })

	return (
		<div className="in-pay-status-filter">
			<h5>Select status</h5>
			<div className="filter-options">
				<label>
					<input
						type="radio"
						name="in_pay_status"
						value={FILTER_TYPES.IN_PAY}
						checked={model === FILTER_TYPES.IN_PAY}
						onChange={onFilterChange}
					/>
					In Pay
				</label>
				<label>
					<input
						type="radio"
						name="in_pay_status"
						value={FILTER_TYPES.ALL}
						checked={model === FILTER_TYPES.ALL}
						onChange={onFilterChange}
					/>
					All
				</label>
			</div>
		</div>
	)
}

export default InPayStatusFilter
