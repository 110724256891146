import React, { useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { AgGridReact } from 'ag-grid-react'
import { isEmpty } from 'lodash'
import {
	AgGridTooltip,
	DateRenderer,
	IconCellRenderer,
	InPayStatusFilter,
	LedgerMonthTotalCellRenderer,
	LedgerActionsRenderer,
	StatusRenderer,
} from 'components'
import {
	currencyFormatter,
	currencyWithDashFormatter,
	dashIfEmptyFormatter,
	enumFormatter,
	ssnFormatter,
} from '../utils/formatters'
import { setReconciliationClass, reconciliationToolTipGetter } from 'utils'

const propTypes = {
	rowData: PropTypes.array.isRequired,
	columnDefs: PropTypes.array.isRequired,
	exportableColumns: PropTypes.array,
	canEdit: PropTypes.bool,
	rowClickPath: PropTypes.string,
	rowClickDataField: PropTypes.string,
	fitToGrid: PropTypes.bool,
	showExportButton: PropTypes.bool,
	onCellValueChanged: PropTypes.func,
	getRowClass: PropTypes.func,
	pagination: PropTypes.bool,
	domLayout: PropTypes.string,
}

const defaultProps = {
	canEdit: false,
	rowClickPath: '',
	rowClickDataField: 'id',
	fitToGrid: true,
	exportableColumns: [],
	showExportButton: true,
	pagination: true,
	domLayout: 'normal',
}

// maps component name coming from the API to actual component
const componentsMap = {
	iconCellRenderer: IconCellRenderer,
	statusRenderer: StatusRenderer,
	dateRenderer: DateRenderer,
	ledgerActionsRenderer: LedgerActionsRenderer,
}

// maps function name coming from the API to actual function
const formattersMap = {
	currencyFormatter,
	currencyWithDashFormatter,
	dashIfEmptyFormatter,
	enumFormatter,
	ssnFormatter,
}

const filtersMap = {
	inPayStatusFilter: InPayStatusFilter,
}

const defaultColumnDef = {
	tooltipComponent: AgGridTooltip,
	filterParams: {
		buttons: ['clear'],
	},
	wrapHeaderText: true,
}

const applyDefaultFilters = (gridApi, columns) => {
	columns.forEach(async (col) => {
		const filterValue = col.filterParams?.model

		if (filterValue) {
			await gridApi.setColumnFilterModel(col.field, filterValue)
			gridApi.onFilterChanged()
		}
	})
}

function AgGridTable({
	rowData,
	columnDefs,
	canEdit,
	rowClickPath,
	rowClickDataField,
	fitToGrid,
	showExportButton,
	onCellValueChanged,
	getRowClass,
	exportableColumns,
	pagination,
	domLayout,
}) {
	const gridRef = useRef()
	const autoSizeStrategy = {
		type: fitToGrid ? 'fitGridWidth' : 'fitCellContents',
	}

	const columnTypes = useMemo(
		() => ({
			protectedColumn: {
				hide: !canEdit,
			},
			iconColumn: {
				width: 60,
				suppressSizeToFit: true,
				resizable: false,
			},
		}),
		[canEdit],
	)

	const columns = useMemo(() => {
		const colDefs = columnDefs.map((col) => {
			let updatedCol = { ...col }

			if (col.cellClass === 'setReconciliationClass') {
				updatedCol.cellClass = setReconciliationClass
			}
			// replace valueFormatter name with the actual function if it exists
			if (formattersMap[col.valueFormatter]) {
				updatedCol.valueFormatter = formattersMap[col.valueFormatter]
			}
			if (col.tooltipValueGetter === 'reconciliationToolTipGetter') {
				updatedCol.tooltipValueGetter = reconciliationToolTipGetter
			}
			if (filtersMap[col.filter]) {
				updatedCol.filter = filtersMap[col.filter]
			}
			return updatedCol
		})

		return colDefs
	}, [columnDefs])

	const exportToCsv = useCallback(() => {
		const exportParams = isEmpty(exportableColumns)
			? { allColumns: true }
			: { columnKeys: exportableColumns }
		gridRef.current.api.exportDataAsCsv(exportParams)
	}, [exportableColumns])

	const onGridReady = useCallback(
		(params) => {
			// default filters need to be manually applied after grid is ready
			applyDefaultFilters(params.api, columns)

			if (fitToGrid) {
				params.api.sizeColumnsToFit()
				window.addEventListener('resize', () => {
					setTimeout(() => {
						params.api.sizeColumnsToFit()
					})
				})
			}
		},
		[fitToGrid, columns],
	)

	const isFullWidthRow = ({ rowNode }) =>
		rowNode.data.rowType === 'ledger_month_total'

	const fullWidthCellRenderer = useCallback(LedgerMonthTotalCellRenderer, [])

	const onRowClicked = (event) => {
		if (rowClickPath) {
			const newPath = rowClickPath.replace(':id', event.data[rowClickDataField])
			window.location.href = newPath
		}
	}

	return (
		<>
			<div className="ag-theme-alpine table">
				<AgGridReact
					ref={gridRef}
					rowData={rowData}
					columnDefs={columns}
					defaultColDef={defaultColumnDef}
					columnTypes={columnTypes}
					pagination={pagination}
					paginationPageSize={50}
					allColumns={true}
					rowHeight="50"
					tooltipShowDelay={500}
					components={componentsMap}
					autoSizeStrategy={autoSizeStrategy}
					onGridReady={onGridReady}
					onRowClicked={onRowClicked}
					onCellValueChanged={onCellValueChanged}
					getRowClass={getRowClass}
					suppressScrollOnNewData={true}
					reactiveCustomComponents={true}
					isFullWidthRow={isFullWidthRow}
					fullWidthCellRenderer={fullWidthCellRenderer}
					domLayout={domLayout}
				/>
			</div>
			{showExportButton && (
				<div className="table-actions">
					<button
						onClick={exportToCsv}
						className="button-primary-outline button-small download"
					>
						Export Data
					</button>
				</div>
			)}
		</>
	)
}

AgGridTable.propTypes = propTypes
AgGridTable.defaultProps = defaultProps

export default AgGridTable
