import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { useSpace } from '@flatfile/react'
import { isEmpty } from 'lodash'
import { ErrorMessage } from 'components'
import { memberListener } from 'listeners'
import { deleteSpace } from 'api'

const propTypes = {
	flatfileConfig: PropTypes.object.isRequired,
	operation: PropTypes.string.isRequired,
	workbook: PropTypes.object.isRequired,
	planFiscalYearDataId: PropTypes.number.isRequired,
	planId: PropTypes.number.isRequired,
}

const defaultProps = {}

async function handleClose(
	spaceId,
	setShowSpace,
	setErrorMessage,
	planFiscalYearDataId,
	invalidMembers,
	planId,
) {
	try {
		await deleteSpace(spaceId)
		setShowSpace(false)
		isEmpty(invalidMembers)
			? (window.location.href = `${window.location.origin}/plan_fiscal_year_data?plan_id=${planId}`)
			: (window.location.href = `${window.location.origin}/plan_fiscal_year_data/${planFiscalYearDataId}/historical_member_data`)
	} catch (e) {
		setErrorMessage(
			'There was an issue exiting. Please contact an administrator.',
		)
	}
}

function ImportHistoricalMemberDataButton({
	flatfileConfig,
	operation,
	planFiscalYearDataId,
	workbook,
	planId,
}) {
	const [showSpace, setShowSpace] = useState(false)
	const [invalidMembers, setInvalidMembers] = useState([])
	const [errorMessage, setErrorMessage] = useState(null)

	const space = useSpace({
		...flatfileConfig,
		workbook,
		listener: memberListener(
			planFiscalYearDataId,
			setInvalidMembers,
			setErrorMessage,
			setShowSpace,
		),
		sidebarConfig: {
			showSidebar: false,
		},
		closeSpace: {
			operation: operation,
			onClose: () => {
				const spaceId = space.props?.spaceId
				handleClose(
					spaceId,
					setShowSpace,
					setErrorMessage,
					planFiscalYearDataId,
					invalidMembers,
					planId,
				)
			},
		},
	})

	return (
		<>
			{errorMessage && (
				<ErrorMessage
					errorMessage={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			)}
			<button
				className="button-primary-outline button-small import panel-cta"
				onClick={() => setShowSpace(!showSpace)}
			>
				{showSpace ? 'Close' : 'Upload Historical Data'}
			</button>
			{showSpace && space}
		</>
	)
}

ImportHistoricalMemberDataButton.propTypes = propTypes
ImportHistoricalMemberDataButton.defaultProps = defaultProps

export default memo(ImportHistoricalMemberDataButton)
