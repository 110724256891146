import { cancelJob } from 'utils'
import { logException } from 'sentry'

async function handleImportError(
	jobId,
	importError,
	errorMessage,
	setErrorMessage,
	setShowSpace,
) {
	setErrorMessage(errorMessage)
	logException(importError)

	await cancelJob(jobId, { info: errorMessage })
	setShowSpace(false)
}

export default handleImportError
