import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'

import { ConfirmationModal } from 'components'
import { deleteAdjustment } from 'api'

const propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		planFiscalYearDataId: PropTypes.number,
		rowType: PropTypes.string,
	}),
}

const defaultProps = {}

function LedgerActionsRenderer({ data }) {
	const [modalShown, setModalShown] = useState(false)

	const handleConfirm = async () => {
		try {
			const response = await deleteAdjustment(
				data.id,
				data.planFiscalYearDataId,
			)
			setModalShown(false)
			window.location = response.redirect_url
		} catch ({ response }) {
			window.location = response.data.redirect_url
		}
	}

	if (data.rowType !== 'adjustment') return null

	return (
		<div className="ledger-actions-renderer">
			<button
				className="button-neutral-outline button-small trash"
				onClick={() => setModalShown(true)}
			/>
			{modalShown && (
				<ConfirmationModal
					modalShown={modalShown}
					setModalShown={setModalShown}
					modalTitle="Delete Adjustment"
					modalContent="You're about to delete this ajustment. This can't be undone. Are you sure?"
					handleConfirm={handleConfirm}
					confirmationText="Yes, Delete"
					isAlert={true}
				/>
			)}
		</div>
	)
}

LedgerActionsRenderer.propTypes = propTypes
LedgerActionsRenderer.defaultProps = defaultProps

export default memo(LedgerActionsRenderer)
