import api from '@flatfile/api'

async function checkForValidationErrors(recordData, jobId) {
	let hasErrors = recordData.some((record) => !record.valid)
	if (hasErrors) {
		await api.jobs.fail(jobId, {
			outcome: {
				message: 'Errors exist in the data. Please correct them and try again.',
			},
		})
		return true
	}
	return false
}

export default checkForValidationErrors
