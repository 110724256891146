import React, { useState, useEffect } from 'react'
import { usePopper } from 'react-popper'
import { createPortal } from 'react-dom'

function Tooltip({ children, referenceElement, visible }) {
	const [popperElement, setPopperElement] = useState(null)
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: 'top',
	})

	const [tooltipElement, setTooltipElement] = useState(null)

	useEffect(() => {
		const div = document.createElement('div')
		setTooltipElement(div)
		document.body.appendChild(div)
		return () => {
			document.body.removeChild(div)
		}
	}, [])

	if (!visible || !tooltipElement) return null

	return createPortal(
		<div
			className="tooltip"
			ref={setPopperElement}
			style={{ ...styles.popper }}
			{...attributes.popper}
		>
			<span>{children}</span>
		</div>,
		tooltipElement,
	)
}

export default Tooltip
