import React, { useRef, useState } from 'react'

import { Tooltip } from 'components'
import { currencyWithDashFormatter } from '../utils/formatters'
import { Check } from '../images'

function EditBankStatementTotal({
	recurringPaymentsTotal,
	bankStatementTotalFieldName,
	bankStatementFormUrl,
}) {
	const [isEditing, setIsEditing] = useState(false)

	return (
		<div className="edit-bank-statement-total">
			<div>
				<span>Recurring Payments Total: </span>
				{currencyWithDashFormatter({ value: recurringPaymentsTotal })}
			</div>
			{isEditing ? (
				<form action={bankStatementFormUrl} method="patch">
					<input
						name={`bank_statement_monthly_benefit[${bankStatementTotalFieldName}]`}
						type="number"
						step={0.01}
						defaultValue={recurringPaymentsTotal}
					/>
					<button type="submit" className="button-primary button-small">
						Save
					</button>
					<button onClick={() => setIsEditing(false)} className="link-primary">
						Cancel
					</button>
				</form>
			) : (
				<button
					onClick={() => setIsEditing(true)}
					className="link-primary button-small edit-icon with-icon"
				>
					Edit
				</button>
			)}
		</div>
	)
}

function LedgerMonthTotalCellRenderer({
	data: {
		monthName,
		totalAdjustments,
		recurringPaymentsTotal,
		bankStatementTotalFieldName,
		bankStatementFormUrl,
	},
}) {
	const checkIconRef = useRef(null)
	const [checkIsHovered, setCheckIsHovered] = useState(false)

	return (
		<div className="ledger-month-total-cell-renderer">
			<div className="ledger-month-totals">
				<EditBankStatementTotal
					recurringPaymentsTotal={recurringPaymentsTotal}
					bankStatementTotalFieldName={bankStatementTotalFieldName}
					bankStatementFormUrl={bankStatementFormUrl}
				/>
				<div>
					<span>{monthName} Total: </span>
					{currencyWithDashFormatter({ value: totalAdjustments })}
				</div>
			</div>
			<div className="totals-checker">
				{recurringPaymentsTotal === totalAdjustments && (
					<>
						<img
							ref={checkIconRef}
							src={Check}
							alt="Recurring Payments and Monthly Total match!"
							onMouseOver={() => setCheckIsHovered(true)}
							onFocus={() => setCheckIsHovered(true)}
							onMouseLeave={() => setCheckIsHovered(false)}
						/>
						<Tooltip
							referenceElement={checkIconRef.current}
							visible={checkIsHovered}
						>
							<span>Recurring Payments and Monthly Total match!</span>
						</Tooltip>
					</>
				)}
			</div>
		</div>
	)
}

export default LedgerMonthTotalCellRenderer
