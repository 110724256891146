import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { startCase } from 'lodash'

const propTypes = {
	value: PropTypes.string,
	data: PropTypes.shape({
		id: PropTypes.shape({
			iconName: PropTypes.string,
		}),
	}).isRequired,
}

const defaultProps = {}

function IconCellRenderer({ data, value }) {
	const { iconName } = data.id
	return value && <span className={iconName}>{startCase(value)}</span>
}

IconCellRenderer.propTypes = propTypes
IconCellRenderer.defaultProps = defaultProps

export default memo(IconCellRenderer)
