import React from 'react'

function AgGridTooltip({ value }) {
	return (
		<>
			{value && (
				<div className="tooltip">
					<span>{value}</span>
				</div>
			)}
		</>
	)
}

export default AgGridTooltip
