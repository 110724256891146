function financialDataSerializer(rows) {
	return rows.reduce((record, row) => {
		if (!row.values.key.value) return record

		const { key, value } = row.values

		// convert Yes/No to boolean
		if (['Yes', 'No'].includes(value.value)) {
			record[key.value] = value.value === 'Yes'
			return record
		}

		record[key.value] = value.value
		return record
	}, {})
}

export default financialDataSerializer
