import { snakeCase } from 'lodash'

function memberSerializer(record) {
	const transformed = {}
	const obj = record.values

	Object.keys(obj).forEach((key) => {
		const snakeCaseKey = snakeCase(key)
		const value = obj[key].value

		transformed[snakeCaseKey] = value
	})

	return transformed
}

export default memberSerializer
