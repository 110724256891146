import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = ['addAnotherRecord', 'newRecord']

	connect() {}

	addAnother(event) {
		event.preventDefault()

		const content = this.newRecordTarget.innerHTML.replace(
			/NEW_RECORD/g,
			new Date().getTime(),
		)
		this.addAnotherRecordTarget.insertAdjacentHTML('beforeend', content)
	}

	remove(event) {
		event.preventDefault()

		let wrapper = event.target.closest('.nested-fields')
		if (wrapper.dataset.newRecord == 'true') {
			wrapper.remove()
		} else {
			wrapper.querySelector("input[name*='_destroy']").value = 1
			wrapper.style.display = 'none'
		}
	}
}
