import React, { memo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const propTypes = {
	value: PropTypes.string,
}

const defaultProps = {}

function DateRenderer({ value }) {
	if (!value) return
	return <span>{moment(value).format('L')}</span>
}

DateRenderer.propTypes = propTypes
DateRenderer.defaultProps = defaultProps

export default memo(DateRenderer)
