import { FlatfileListener } from '@flatfile/listener'
import { recordHook } from '@flatfile/plugin-record-hook'
import {
	completeJob,
	failJob,
	getRowsAndFieldConfigs,
	splitNameByComma,
	memberFlatfileValidations,
	memberSerializer,
	checkForValidationErrors,
	handleImportError,
} from 'utils'
import { createCurrentYearMemberData, createHistoricalMemberData } from 'api'

function serializeMembers(recordData) {
	return recordData.map((record) => memberSerializer(record))
}

const MEMBER_IMPORT_ERROR =
	'There was an issue importing members. Please contact an administrator.'

function memberListener(
	planFiscalYearDataId,
	setData,
	setErrorMessage,
	setShowSpace,
) {
	return FlatfileListener.create((listener) => {
		listener.use(
			recordHook('members', (record) => {
				splitNameByComma(record)
				memberFlatfileValidations(record)
				return record
			}),
		)

		listener.filter(
			{ job: 'workbook:currentYearMembers:submit' },
			(configure) => {
				configure.on(
					'job:ready',
					async ({ context: { jobId, workbookId } }) => {
						try {
							const { rows } = await getRowsAndFieldConfigs(workbookId)

							if (!(await checkForValidationErrors(rows, jobId))) {
								try {
									const memberRecords = serializeMembers(rows)
									const updatedMembers = await createCurrentYearMemberData(
										memberRecords,
										planFiscalYearDataId,
									)
									setData(updatedMembers)

									await completeJob(jobId)
								} catch (importError) {
									await handleImportError(
										jobId,
										importError,
										MEMBER_IMPORT_ERROR,
										setErrorMessage,
										setShowSpace,
									)
								}
							}
						} catch (error) {
							await failJob(jobId)
						}
					},
				)
			},
		)

		listener.filter(
			{ job: 'workbook:historicalMembers:submit' },
			(configure) => {
				configure.on(
					'job:ready',
					async ({ context: { jobId, workbookId } }) => {
						try {
							const { rows } = await getRowsAndFieldConfigs(workbookId)

							if (!(await checkForValidationErrors(rows, jobId))) {
								try {
									const memberRecords = serializeMembers(rows)

									const invalidMembers = await createHistoricalMemberData(
										memberRecords,
										planFiscalYearDataId,
									)
									setData(invalidMembers)

									await completeJob(jobId)
								} catch (importError) {
									await handleImportError(
										jobId,
										importError,
										MEMBER_IMPORT_ERROR,
										setErrorMessage,
										setShowSpace,
									)
								}
							}
						} catch (error) {
							await failJob(jobId)
						}
					},
				)
			},
		)
	})
}

export default memberListener
