import * as Images from 'images'

export const MEMBER_DATA_STATUSES_BY_USER_ROLE = {
	admin: 'reviewed',
	internal: 'reviewed',
	external: 'submitted',
}

export const reconciliationList = [
	{
		icon: Images.WarningCircleIcon,
		legend:
			'Changes have occurred in this row. Click the icon to navigate to each respective change.',
		historical: true,
	},
	{
		icon: Images.ErrorIcon,
		legend: 'Validation error: Required information is missing or invalid.',
		historical: true,
	},
	{
		icon: Images.WarningIcon,
		legend:
			'Data change detected within an individual cell from the latest import. No action required, but should be reviewed.',
		historical: false,
	},
	{
		icon: Images.Comments,
		legend: 'One or more comments are attached to the individual cell or row.',
		historical: false,
	},
	{
		icon: Images.NewMember,
		legend:
			'New member detected in the latest import. You can review and approve or mark as duplicate.',
		historical: false,
	},
	{
		icon: Images.QuestionMark,
		legend:
			'Member missing from the previous import. Please review data and update and approve as necessary.',
		historical: false,
	},
]
