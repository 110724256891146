import api from '@flatfile/api'
import { first } from 'lodash'

async function getRowsAndFieldConfigs(workbookId) {
	const wb = await api.workbooks.get(workbookId)
	const sheet = first(wb.data.sheets)
	const records = await api.records.get(sheet.id)

	return {
		rows: records.data.records,
		fieldConfigs: sheet.config.fields,
	}
}

export default getRowsAndFieldConfigs
