import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { initializeFlatfile } from '@flatfile/react'
import { memberListener } from 'listeners'
import { deleteSpace } from 'api'

const propTypes = {
	flatfileConfig: PropTypes.object.isRequired,
	operation: PropTypes.string.isRequired,
	planFiscalYearDataId: PropTypes.number,
	setData: PropTypes.func,
	setErrorMessage: PropTypes.func,
	workbook: PropTypes.object.isRequired,
}

const defaultProps = {}

async function handleClose(spaceId, setShowSpace, setErrorMessage) {
	try {
		await deleteSpace(spaceId)
		setShowSpace(false)
	} catch (e) {
		setErrorMessage(
			'There was an issue exiting. Please contact an administrator.',
		)
	}
}

function ImportCurrentYearMemberDataButton({
	flatfileConfig,
	planFiscalYearDataId,
	operation,
	setData,
	setErrorMessage,
	workbook,
}) {
	const [showSpace, setShowSpace] = useState(false)

	const { Space, OpenEmbed } = initializeFlatfile({
		...flatfileConfig,
		workbook,
		listener: memberListener(
			planFiscalYearDataId,
			setData,
			setErrorMessage,
			setShowSpace,
		),
		sidebarConfig: {
			showSidebar: false,
		},
		closeSpace: {
			operation: operation,
			onClose: () => {
				const spaceId = Space().props?.spaceId
				handleClose(spaceId, setShowSpace, setErrorMessage)
			},
		},
	})

	const onOpenSpace = async () => {
		setShowSpace(!showSpace)
		await OpenEmbed()
	}

	return (
		<>
			<button
				className="button-primary-outline button-small import"
				onClick={onOpenSpace}
			>
				{showSpace ? 'Close' : 'Import Members'}
			</button>
			{showSpace && <Space />}
		</>
	)
}

ImportCurrentYearMemberDataButton.propTypes = propTypes
ImportCurrentYearMemberDataButton.defaultProps = defaultProps

export default memo(ImportCurrentYearMemberDataButton)
